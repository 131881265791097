<template>
    <div class="voice-database">
        <div class="top">
            <div class="title font-bold">
                配音員搜尋
                <div class="red-line-l"></div>
            </div>
            <Search :filter="filter" @search="search"></Search>
        </div>
        <q-card class="result" id="result">
            <q-inner-loading
                :showing="loading"
                label="載入中..."
                label-style="color: black"
            />
            <div v-if="!loading">
                <div class="h2 font-bold">結果</div>
                <div v-if="list.length === 0" style="text-align: center;">查詢無資料</div>
                <div class="item" v-for="item in list" :key="item.no">
                    <div class="top-item">
                        <div class="info">
                            <img
                                class="avatar"
                                :src="avatarImg(item.avatar)"
                                v-if="avatarImg(item.avatar)"
                            />
                            <img
                                class="avatar"
                                src="../assets/voice-database/default-male.png"
                                alt=""
                                v-if="!avatarImg(item.avatar) && item.gender.trim() == '男'"
                            />
                            <img
                                class="avatar"
                                src="../assets/voice-database/default-female.png"
                                alt=""
                                v-if="!avatarImg(item.avatar) && item.gender.trim() == '女'"
                            />
                            <div class="info-data">
                                <div class="name font-bold">
                                    {{ item.name }}
                                    <div class="red-line"></div>
                                </div>
                                <div class="gender">
                                    {{ item.gender }}
                                </div>
                            </div>
                        </div>
                        <div class="link-box">
                            <a
                                class="video-link link"
                                :href="item.videoLink"
                                target="_blank"
                                v-if="item.videoLink"
                            >
                                <img
                                    src="../assets/voice-database/youtube-icon-mobile.png"
                                    alt=""
                                    class="icon is-mobile"
                                />
                                <img
                                    src="../assets/voice-database/youtube-icon.png"
                                    alt=""
                                    class="icon is-desktop"
                                />
                                <div class="text is-desktop">影片連結</div>
                            </a>
                            <a
                                class="sns-link link"
                                :href="item.sns"
                                target="_blank"
                                v-if="item.sns"
                            >
                                <img
                                    src="../assets/voice-database/sns-icon-mobile.png"
                                    alt=""
                                    class="icon is-mobile"
                                />
                                <img
                                    src="../assets/voice-database/sns-icon.png"
                                    alt=""
                                    class="icon is-desktop"
                                />
                                <div class="text is-desktop">社群連結</div>
                            </a>
                        </div>
                    </div>
                    <div class="embed">
                        <div class="voice-frame"
                        v-for="(frame, index) in item.voiceFrame" :key="index" v-html="frame">
                        </div>
                    </div>
                    <div class="category-list">
                        <div class="voice-gender line">
                            <img
                                src="../assets/voice-database/type-icon.png"
                                alt=""
                                class="icon"
                            />
                            <span class="label">聲線種類: </span>
                            <div class="tags">
                                <div
                                    class="tag"
                                    v-for="tag in item.voiceType"
                                    :key="tag"
                                >
                                    <div v-if="tag" class="tag-text">
                                        {{ tag }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="langue-type line">
                            <img
                                src="../assets/voice-database/langue-icon.png"
                                alt=""
                                class="icon"
                            />
                            <span class="label">語種: </span>
                            <div class="tags">
                                <div
                                    class="tag"
                                    v-for="tag in item.langue"
                                    :key="tag"
                                >
                                    <div v-if="tag" class="tag-text">
                                        {{ tag }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dubbing-type line">
                            <img
                                src="../assets/voice-database/voice-icon.png"
                                alt=""
                                class="icon"
                            />
                            <span class="label">配音種類: </span>
                            <div class="tags">
                                <div
                                    class="tag"
                                    v-for="tag in item.dubbingType"
                                    :key="tag"
                                >
                                    <div v-if="tag" class="tag-text">
                                        {{ tag }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="description">
                        {{ item.introduction }}
                    </div>
                </div>
                <q-pagination
                    class="pagination"
                    v-model="currentPage"
                    :max="maxPage"
                    :max-pages="5"
                    :ellipses="false"
                    :boundary-numbers="false"
                    input
                />
            </div>
        </q-card>
    </div>
</template>

<script>
import _ from 'lodash';
import Search from '@/components/Search.vue';
// import VoiceData from '../assets/data/voice-data.json';
import API from '@/api';

export default {
    components: {
        Search,
    },
    data() {
        return {
            loading: true,
            voiceData: [],
            currentPage: 1,
            limit: 10,
            filter: {
                gender: '',
                voiceType: '',
                langue: '',
                dubbingType: '',
                text: '',
            },
        };
    },
    computed: {
        totalList() {
            const tmpList = _.chain(this.voiceData)
                .filter((i) => {
                    if (this.filter.gender) {
                        if (i.gender) {
                            return i.gender === this.filter.gender;
                        }
                        return false;
                    }
                    return i;
                })
                .filter((i) => {
                    if (this.filter.voiceType) {
                        if (i.voiceType && i.voiceType.length > 0) {
                            return i.voiceType.indexOf(this.filter.voiceType) !== -1;
                        }
                        return false;
                    }
                    return i;
                })
                .filter((i) => {
                    if (this.filter.langue) {
                        console.log('this.filter.langue', this.filter.langue);
                        if (i.langue && i.langue.length > 0) {
                            return i.langue.indexOf(this.filter.langue) !== -1;
                        }
                        return false;
                    }
                    return i;
                })
                .filter((i) => {
                    if (this.filter.dubbingType) {
                        if (i.dubbingType && i.dubbingType.length > 0) {
                            return i.dubbingType.indexOf(this.filter.dubbingType) !== -1;
                        }
                        return false;
                    }
                    return i;
                })
                .filter((i) => {
                    if (this.filter.text) {
                        return i.name.includes(this.filter.text)
                            || i.introduction.includes(this.filter.text);
                    }
                    return i;
                })
                .value();
            return tmpList;
        },
        list() {
            const resultElement = document.querySelector('#result');
            if (resultElement) {
                const { offsetTop } = resultElement;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
            }
            return _.slice(this.totalList, (this.currentPage - 1) * this.limit,
                (this.currentPage * this.limit));
        },
        maxPage() {
            const divide = Math.floor(this.totalList.length / this.limit);
            if (this.totalList.length % this.limit === 0) {
                return divide;
            }
            return divide + 1;
        },
    },
    created() {
        this.getData();
        this.init();
    },
    mounted() { },
    methods: {
        async getData() {
            try {
                const promises = await API.postData();
                this.voiceData = this.parseData(promises.data);
                this.loading = false;
            } catch (error) {
                console.log(error);
            }
        },
        init() {
            this.filter.gender = this.$route.query.gender ? this.$route.query.gender : '';
            this.filter.voiceType = this.$route.query.voiceType ? this.$route.query.voiceType : '';
            this.filter.langue = this.$route.query.langue ? this.$route.query.langue : '';
            this.filter.dubbingType = this.$route.query.dubbingType ? this.$route.query.dubbingType : '';
            this.filter.text = this.$route.query.text ? this.$route.query.text : '';
            this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
            this.search(this.filter);
        },
        parseData(data) {
            data.map((item) => {
                const temp = { ...item };
                if (item.noShowData === '不上資料') {
                    temp.introduction = '';
                    temp.voiceType = [];
                    temp.langue = [];
                    temp.dubbingType = [];
                    temp.videoLink = '';
                    temp.sns = '';
                    temp.voiceFrame = [];
                }
                return temp;
            });
            const transformedData = data.map((item) => {
                const transformedItem = {
                    ...item,
                    name: String(item.name).replace(/ /g, ''),
                    voiceType: item.voiceType.replace(/ /g, '').split(','),
                    langue: item.langue.replace(/ /g, '').split(','),
                    dubbingType: item.dubbingType.replace(/ /g, '').split(','),
                    voiceFrame: this.addBottomFrame(item.voiceFrame.split('</a></div>,')),
                };
                return transformedItem;
            });

            return transformedData;
        },
        avatarImg(link) {
            if (/^https:\/\/drive\.google\.com/.test(link)) {
                // eslint-disable-next-line no-unused-vars
                const [unused, match1, match2] = link.match(/\/d\/([^/]+)|open\?id=([^&]+)/);
                const fileId = match1 || match2;
                return `https://drive.google.com/thumbnail?export=view&id=${fileId}`;
            }
            return undefined;
        },
        addBottomFrame(array) {
            if (array.length > 0) {
                return array.map((str) => `${str}</a></div>`);
            }
            return array;
        },
        search(form) {
            // this.loading = true;
            this.currentPage = 1;
            this.$router.replace({
                query: {
                    gender: form.gender,
                    voiceType: form.voiceType,
                    langue: form.langue,
                    dubbingType: form.dubbingType,
                    text: form.text,
                    page: this.currentPage,
                },
            });
            this.filter = form;
        },
        voiceFrameConvert(string) {
            if (string) {
                console.log('string', string);
                console.log('replace', string.replace(/\\\\"/g, '"'));
                return string.replace(/\\\\"/g, '"');
            }
            return string.replace(/\\\\"/g, '"');
        },
    },
};
</script>

<style lang="scss">
.is-desktop {
    display: flex;
}

.is-mobile {
    display: none;
}
.voice-database {
    .top {
        padding: 60px 0;
        width: 100vw;
        background-color: #BDA996;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
            font-size: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 29px;
        }
    }

    .embed{
        margin-top: 20px;
        .voice-frame {
            margin-top: 15px;
        }
    }

    .result {
        background-image: url('../assets/voice-database/result-bg.png');
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;

        .h2 {
            font-size: 20px;
            margin-bottom: 45px;
            width: 80vw;
            max-width: 900px;
            display: flex;
            justify-content: flex-start;
        }

        .item {
            background-color: #f9fafb;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            padding: 32px 65px;
            width: 80vw;
            max-width: 900px;
            margin-bottom: 40px;
            .top-item {
                border-bottom: 2px solid rgba(188, 14, 14, 0.15);
                padding-bottom: 22px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                .link-box {
                    display: flex;
                    .link:not(:last-child) {
                        margin-right: 25px;
                    }
                }

                .link {
                    display: flex;
                    font-size: 16px;
                    text-decoration: none;
                    align-items: center;
                    color: black;
                    .icon {
                        margin-right: 7px;
                        width: 18px;
                        height: 12px;
                    }
                }
                .info {
                    display: flex;
                    .avatar {
                        width: 92px;
                        height: 92px;
                        margin-right: 27px;
                        object-fit: contain;
                    }
                    .info-data {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .name {
                            font-size: 24px;
                            margin-bottom: 7px;
                            .red-line {
                                width: 72px;
                                height: 3px;
                                background: #bc0e0e;
                                margin-top: 7px;
                            }
                        }
                        .gender {
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                }
            }

            .description {
                font-size: 20px;
                line-height: 30px;
                white-space: pre-line;
                word-wrap: break-word;
            }

            .category-list {
                display: flex;
                flex-direction: column;
                margin-top: 23px;
                margin-bottom: 20px;
                padding-bottom: 23px;
                border-bottom: 2px solid rgba(188, 14, 14, 0.15);
                flex-wrap: wrap;
                .line {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    flex-grow: 0;
                    margin-bottom: 10px;
                    .icon {
                        width: 24px;
                        height: 24px;
                        margin-right: 2px;
                    }

                    .label {
                        font-size: 18px;
                        margin-right: 17px;
                        flex-shrink: 0;
                    }
                    .tags {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                    }
                    .tag {
                        margin-top: 3px;
                        margin-bottom: 3px;
                    }
                    .tag-text {
                        font-size: 18px;
                        padding: 1px 13px;
                        background: #e9e9e9;
                        border: 1px solid #b6b6b6;
                        border-radius: 2px;
                        flex-shrink: 0;
                    }

                    .tag:not(:last-child) {
                        margin-right: 9px;
                    }
                }
            }
        }

        .pagination {
            display: flex;
            justify-content: center;
            margin-top: 57px;

            .bg-primary {
                background: #bc0e0e !important;
            }

            .text-primary {
                color: #bc0e0e !important;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .voice-database {
        .is-desktop {
            display: none;
        }
        .is-mobile {
            display: flex;
        }
        .top {
            .title {
                font-size: 28px;
                margin-bottom: 36px;
            }
        }
        .result {
            .h2 {
                font-size: 14px;
                margin-bottom: 27px;
                width: 90vw;
            }
            .item {
                width: 90vw;
                padding: 23px;
                .top-item {
                    padding-bottom: 13px;

                    .link {
                        .icon {
                            margin-right: 0;
                            width: 39px;
                            height: 25px;
                        }
                    }

                    .link-box {
                        display: flex;
                        .link:not(:last-child) {
                            margin-right: 6px;
                        }
                    }

                    .info {
                        .avatar {
                            width: 70px;
                            height: 70px;
                            margin-right: 16px;
                        }
                        .info-data {
                            .name {
                                font-size: 20px;
                            }
                            .gender {
                                font-size: 14px;
                            }
                        }
                    }
                }
                .category-list {
                    margin-bottom: 15px;
                    padding-bottom: 17px;
                    .line {
                        .icon {
                            width: 20px;
                            height: 20px;
                        }
                        .label {
                            font-size: 14px;
                        }
                        .tag {
                            border-radius: 30px;
                            font-size: 14px;
                        }
                    }
                }
                .description {
                    font-size: 14px;
                }
            }

            .pagination {
                margin-top: 28px;
            }
        }
    }
}
</style>
