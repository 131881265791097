import axios from 'axios';

class API {
    constructor() {
        this.baseUrl = process.env.VUE_APP_URL;
    }

    postData() {
        return axios.post(this.baseUrl);
    }
}

export default new API();
